<template>
  <v-card :width="width" height="340">
    <v-card-title>{{ $t('playing_statistic.title') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" md="4" xl="3">{{ $t('playing_statistic.work_mode.title') }}: </v-col>
        <v-col>
          <span v-text="$t(currentMode)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" xl="3">{{ $t('playing_statistic.status.title') }}: </v-col>
        <v-col>
          <span v-text="$t(playingStatus)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols="6" md="4" xl="3">{{ $t('playing_statistic.playlist') }}:</v-col>
        <v-col>
          <span v-text="playingInfo.playlist" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" xl="3">{{ $t('playing_statistic.scene') }}: </v-col>
        <v-col>
          <span v-text="playingInfo.scene ? parseInt(playingInfo.scene) : ''" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" xl="3">{{ $t('playing_statistic.cue') }}: </v-col>
        <v-col>
          <span v-text="playingInfo.cue" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="4" xl="3">{{ $t('playing_statistic.progress') }}: </v-col>
        <v-col class="pt-5 pb-0">
          <progress-bar
          class="align-center justify-center"
            size="small"
            :val="progress_bar"
            :text="frame_count > 0 ? $tc('playing_statistic.seconds', frame_count) : ''"
            :bar-transition="transactionTime"
          ></progress-bar>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProgressBar from 'vue-simple-progress'

export default {
  props: ["width",],
  components: {
    ProgressBar,
  },
  data: function() {
    return {
      progress_ws_connection: undefined,
      entity_ws_connection: undefined,
      frame_count: 0,
      progress_bar: 0,
    }
  },
  computed: {
    currentMode() {
      return this.$store.getters['mainSettings/getLabelOfCurrentMode']
    },
    playingInfo () {
      return this.$store.state.playingInfo
    },
    isPlaying() {
      return Boolean(this.playingInfo.cue)
    },
    playingStatus() {
      if (this.isPlaying) {return 'playing_statistic.status.is_playing'}
      else {return 'playing_statistic.status.is_not_playing'}
    },
    /**
     * @returns {number} Частота отправки кадров в секундах.
     */
    fpsTime() {
      return 1 / this.$store.getters['mainSettings/getFps']
    },
    /**
     * @returns {string} Плавность перехода в css.
     */
    transactionTime() {
      let trTime = this.fpsTime ? (this.fpsTime * 20).toFixed(2) : 0.50
      return `width ${trTime}s linear 0s`
    }
  },
  methods: {
    connectToPlayingProgressStatisticWebsocket() {
      this.$store.dispatch('connectToWebsocket', 'ws/statistic/progress/').then( (wsConn) => {
        this.progress_ws_connection = wsConn
        this.progress_ws_connection.onmessage = (event) => {
          event.data.text().then(text => {
            let msg = text.split(',')
            let currentPacket = parseInt(msg[0])
            let totalPacket = parseInt(msg[1])
            let progressPercent = (currentPacket / totalPacket * 100).toFixed(1)
            this.progress_bar = progressPercent
            this.frame_count = (currentPacket * this.fpsTime).toFixed(0)
          })
        }
      })
    },
    connectToPlayingEntityStatisticWebsocket() {
      this.$store.dispatch('connectToWebsocket', 'ws/statistic/entity/').then( (wsConn) => {
        this.entity_ws_connection = wsConn
        this.entity_ws_connection.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            this.$store.commit('setPlayingInfo', serialized_msg)
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('getPlayingInfo')
    this.connectToPlayingProgressStatisticWebsocket()
    this.connectToPlayingEntityStatisticWebsocket()
  },
  destroyed() {
    this.progress_ws_connection.close()
    this.entity_ws_connection.close()
  },
}
</script>
