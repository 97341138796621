<template>
  <div>
    <v-row class="py-5">
      <v-col
        cols="12" sm="6" md="6" lg="6"
      >
        <playing-statistic width="95%"></playing-statistic>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row 
      v-for="(value, key, index) in logs" :key="index"
      class="py-5"
    >
      <v-col>
        <v-card width="97.5%">
          <v-card-title>{{ $t('home_page.system_info.service_log') }}</v-card-title>
          <v-card-text>
            <p
              v-for="(logRow, index) in value" :key="index"
            >
            {{logRow}}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="py-5" justify="center">
      <v-btn color="primary--text"  @click="downloadDebugInfo">{{ $t('home_page.system_info.collect_debug_button') }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import Api from "@/api/api"
import playingStatistic from '@/components/PlayingStatistic.vue'

export default {
  components: {
    playingStatistic,
  },
  data() {
    return {
    }
  },
  computed: {
    logs() {
      return this.$store.state.logs.logs
    }
  },
  methods: {
    downloadDebugInfo() {
      Api.downloadDebugInfo()
      .then( response => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '')
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = fileName; //or any other extension
        // document.body.appendChild(link);
        link.click();
      })
    },
  },
  created () {
    this.$store.dispatch('logs/getLogs')
    .then( () => {
      this.$store.dispatch('logs/connectToLogsWebsocket')
    })
  },
  destroyed () {
    this.$store.dispatch('logs/disconnectFromLogsWebsocket')
  },
}
</script>
